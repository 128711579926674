<template lang="pug">
    .management-layouts__issue
        IssueFromBDI
</template>

<script>
import IssueFromBDI from '@/pages/bdi/ListOfIssues'
export default {
    name:'ManagementPageShell',
    components: {
        IssueFromBDI
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    created() {
       
    }
}
</script>

<style lang="sass">
    .management-layouts__issue
        padding-left: 255px
        padding-right: 24px
</style>